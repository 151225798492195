const education = [
  {
    name: "삼성 청년 SW 아카데미",
    period: "2022.01 - 2022.12",
    title: ["Web Development : Fontend / Backend "],
  },
  {
    name: "서울시립대학교 ",
    title: "Bachelor of Computer Science and Engineering",
    period: "2016.03 - 2021.02 ",
  },
]

const awards = [
  {
    name: "삼성 청년 SW아카데미 - 관통, 공통, 자율, 특화 프로젝트 ( 각각 1등 ) 및 전국 결선 입상",
    period: "2022.05~2022.12",
  },
  // {
  //   name: "삼성 청년 SW아카데미 특화 프로젝트 우수상 (1등)",
  //   period: "2022.10.07",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 공통 프로젝트 우수상 (1등)",
  //   period: "2022.08.19",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 관통 프로젝트 최우수상 (1등)",
  //   period: "2022.05.27",
  // },
]
const careers = [
  {
    name: "CJ OliveNetworks ",
    period: "2023.01 - ✨ Current ✨",
    title: "BI Engineer  ·  SAP Solution Engineer ",
    description: [
      " Date Warehouse 운영/개발 - Data ETL  프로세스 관리  ",
      "손익 및 영업 매출 시각화 Dashboard 개발",
      "SAP BW Query / SQL View 개발",
      "데이터 모델링 (SAP BW, SAC, Datasphere)",
    ],
    skills: [
      "SQL",
      "Database",
      "SAP BW",
      "SAP Analytics Cloud",
      "SAP Datasphere",
    ],
  },
]

export { education, awards, careers }
