import React from "react"
import Experience from "../components/experience/Experience"
import Profile from "../components/profile/Profile"
import Projects from "../components/projects/Projects"
import Skills from "../components/Skills/Skills"
import TistoryRSSFeed from "../components/rss/TistoryRSSFeed"

function Main(props) {
  return (
    <>
      <Profile />
      <Experience />
      <TistoryRSSFeed />
      {/* <Projects /> */}
      {/* <Skills /> */}
    </>
  )
}

export default Main
