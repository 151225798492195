const projects = [
  {
    id: 1,
    name: " 집에서 운동중 ",
    title: "Frontend Developer / Team Project ",
    github: "https://github.com/camiyoung/ZipZong",
    skills: ["react", "redux", "tailwind"],
    brief: "화상 채팅을 통한 운동 수행 횟수 측정 서비스 ",
    description: [
      "Redux를 이용한 React 컴포넌트 전역 상태 관리 구현",
      "React Life Cycle 을 고려한 실시간 화상 채팅 기능 구현( with Open Vidu) ",
      "운동 동작 수행 count 측정 기능 구현 (with Google Teachable Machine )",
    ],
    period: "2022.07.05 - 2022.08.19",
  },
  {
    id: 2,
    name: " 드림멍즈",
    title: " Frontend Developer  / Team Project ",
    github: "https://github.com/camiyoung/DreamMungz",
    skills: ["react", "typescript", "react-query", "recoil", "tailwind"],
    period: "2022.08.22 - 2022.10.07",
    brief: "NFT 발급/거래 기능이 가능한 Web Game 서비스 ",
    description: [
      "필터 적용 검색 기능 구현. 34개의 필터 적용 및 해제 기능 구현 ",
      "재사용 가능한 Component 제작 (Pagenation, Tab, Badge, Card List)",
      "Three.js 을 이용한 3D 전시장 구현",
    ],
  },
  {
    id: 3,
    name: " Starry Night ",
    title: " Unity Developer / Team Project",
    github: "https://github.com/camiyoung/StarryNight",
    skills: ["unity", "react"],
    period: "2022. 10 .11 - 2022. 11. 25",
    brief: "시간/위치 기반 3D 별자리 관측 서비스",
    description: [
      "현실의 시간/위치를 고려한 별자리 위치 계산 시스템 구현 ",
      "사용자간 멀티 플레이 기능 구현",
      "VR 기기 연동 플레이 기능 구현",
    ],
  },
  {
    id: 5,
    name: " 편한 집 찾기 ",
    title: "Full Stack Developer / Team Project",
    github: "https://github.com/SSAFY7-16-7/HappyHouse",
    skills: ["vue", "vuex", "springboot", "mysql"],
    period: "2022.05.19 - 2022.05.26",
    brief: "공공 데이터와 지도 API를 활용한 거래 정보 조회 서비스",
    description: [
      "Kakao Map API 와 아파트 거래 공공 데이터를 활용한 거래 정보 표현",
      "Spring MVC 아키텍처를 적용한 게시글 작성 및 북마크 기능 구현",
    ],
  },

  {
    id: 4,
    name: " 실시간 채팅 기능 서비스  ",
    title: " Full Stack Developer / Personal Project",
    github: "https://github.com/camiyoung/chat-with-react",
    skills: ["react", "Node.js", "Express.js", "Socket.io"],
    period: "2021. 06",
    brief: "Socket.io를 활용한 채팅 서비스 ",
    description: ["채팅방 개설  및 실시간 대화 서비스 구현"],
  },
]
export default projects
