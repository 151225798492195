import React from "react"

function Avatar() {
  return (
    <div className="avatar">
      <div className="rounded-lg w-72 ring ring-primary ring-offset-base-100 ring-offset-2">
        <img src="/images/jeju.PNG" />
      </div>
    </div>
  )
}

export default Avatar
