const introduce = [
  "BI Engineer  ·  SAP Solution Engineer ",
  "Skilled  : BW, Datasphere, SAC ",
]

const urls = {
  github: "https://github.com/camiyoung",
  blog: "https://anji0.tistory.com/",
  email: "anji6978@naver.com",
}

export { introduce, urls }
